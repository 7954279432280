import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RELEASE_DATE } from "../constants";
const data = [
  {
    title: "policy_title1",
    description: "policy_description1",
  },
  {
    title: "policy_title2",
    description: "policy_description2",
  },
  {
    title: "policy_title3",
    description: "policy_description3",
  },
  {
    title: "policy_title4",
    description: "policy_description4",
  },
  {
    title: "policy_title5",
    description: "policy_description5",
  },
  {
    title: "policy_title6",
    description: "policy_description6",
  },
  {
    title: "policy_title7",
    description: "policy_description7",
  },
  {
    title: "policy_title8",
    description: "policy_description8",
  },
  {
    title: "policy_title9",
    description: "policy_description9",
  },
];
export const PrivacyPolicy = (props) => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div id="privacyPolicyModal">
      <div>
        <div>
          <div className="modal-body">
            {/* <p className="effective-date">
              {t("pp_effective_date")}:{" "}
              <span>
                {new Date("2023-11-01").toLocaleDateString(i18n.language)}
              </span>
            </p> */}
            <h2>{t("pp_privacy_policy")}</h2>
            <p>{t("pp_privacy_policy_content")}</p>
            {data.map((item, index) => (
              <div className="privacy_policy_item" key={index}>
                <h3>{t(item.title)}</h3>
                <p style={{ whiteSpace: "pre-wrap" }}>
                  {t(item.description)
                    .split("\n")
                    .map((line, ind) => (
                      <React.Fragment key={ind}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                </p>
              </div>
            ))}
            <a href="mailto:cs@ai-prop.com">cs@ai-prop.com</a>
            <br />
            <br />
            <p>{new Date(RELEASE_DATE).toLocaleDateString(i18n.language)}</p>
            <p>{t("pp_company")}</p>
            <p>{t("pp_ceo")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
