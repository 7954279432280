import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RELEASE_DATE } from "../constants";
export const TermOfService = (props) => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div id="TermOfServiceModal">
      <div>
        <div>
          <div className="modal-body">
            {/* <p className="effective-date">
              {t("pp_effective_date")}:{" "}
              <span>
                {new Date("2023-11-01").toLocaleDateString(i18n.language)}
              </span>
            </p> */}
            <h2>{t("tac_intro")}</h2>
            <p>{t("tac_des")}</p>

            {Array.from({ length: 18 }, (_, i) => i + 1).map((i) => (
              <div className="privacy_policy_item" key={i}>
                <h3>{t("tac_item" + i)}</h3>
                <p style={{ whiteSpace: "pre-wrap" }}>
                  {t("tac_description" + i)
                    .split("\n")
                    .map((line, ind) => (
                      <React.Fragment key={ind}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                </p>
              </div>
            ))}
            <br />
            <br />
            <p>{new Date(RELEASE_DATE).toLocaleDateString(i18n.language)}</p>
            <p>{t("pp_company")}</p>
            <p>{t("pp_ceo")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
