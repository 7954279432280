import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Particles from "react-particles";
//import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "tsparticles-slim";
import { useCallback } from "react";
import Logo from "../assets/ai_prop_logo.png";
import LogoJa from "../assets/ai_prop_logo_ja.png";
import { toast } from "react-toastify";
import axios from "axios";
import { BACKEND_URL } from "../constants";
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

// Function to validate email
function validateEmail(email) {
  return emailRegex.test(email);
}
export const ComingSoon = (props) => {
  const { t, i18n } = useTranslation();
  const isMobile = window.innerWidth < 768;
  const isTablet = 768 < window.innerWidth && window.innerWidth <= 1024;

  const [email, setEmail] = useState(undefined);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    //await loadFull(engine);
    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    //Validate form

    if (!validateEmail(email)) {
      toast.error(t(`contact_email_invalid`));
      return;
    }
    axios
      .post(BACKEND_URL + "/api/v1/public/contact", {
        name: "Guest",
        email,
        message:
          "I am interested in your product. Please contact me when it is available.",
        language: localStorage.getItem("i18nextLng"),
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success(t("contact_success"));
          setEmail(undefined);
        } else {
          toast.error(t("contact_error"));
        }
        // assign image to the target element
      })
      .catch((error) => {
        toast.error(t("contact_error"));
      });
  };
  return (
    <div id="comingSoon">
      <Particles
        id="tsparticles"
        // url="http://foo.bar/particles.json"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          // background: {
          //     color: {
          //         value: "#0d47a1",
          //     },
          // },
          fpsLimit: 120,
          interactivity: {
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onHover: {
                enable: true,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 200,
                duration: 1,
              },
            },
          },
          // particles: {
          //     color: {
          //         value: "#ffffff",
          //     },
          //     links: {
          //         color: "#ffffff",
          //         distance: 150,
          //         enable: true,
          //         opacity: 0.5,
          //         width: 1,
          //     },
          //     move: {
          //         direction: "none",
          //         enable: true,
          //         outModes: {
          //             default: "bounce",
          //         },
          //         random: false,
          //         speed: 3,
          //         straight: false,
          //     },
          //     number: {
          //         density: {
          //             enable: true,
          //             area: 800,
          //         },
          //         value: 80,
          //     },
          //     opacity: {
          //         value: 0.5,
          //     },
          //     shape: {
          //         type: "circle",
          //     },
          //     size: {
          //         value: { min: 1, max: 5 },
          //     },
          // },
          //   detectRetina: true,
          particles: {
            links: {
              enable: true,
            },
            move: {
              enable: true,
            },
            number: {
              value: isMobile ? 30 : isTablet ? 80 : 100,
            },
            opacity: {
              value: { min: 0.3, max: 1 },
            },
            shape: {
              type: ["circle", "square", "triangle", "polygon"],
              options: {
                polygon: [
                  {
                    sides: 5,
                  },
                  {
                    sides: 6,
                  },
                  {
                    sides: 8,
                  },
                ],
              },
            },
            size: {
              value: { min: 1, max: 3 },
            },
          },
        }}
      />

      <div className="modal-body">
        <img src={i18n.language === "ja" ? LogoJa : Logo} alt="AI-prop logo" />
        <h2>{t("coming_soon")}</h2>
        <p>{t("coming_soon_description")}</p>
        <div class="newsletter-form">
          <form action="#" method="post">
            <input
              type="text"
              class="new-text"
              placeholder={t("email")}
              required=""
              value={email ?? ""}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button type="submit" class="new-btn" onClick={handleSubmit}>
              <i class="fa fa-send"></i> {t("contact")}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
