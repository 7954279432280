/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  A11y,
  Navigation,
  Autoplay,
  EffectFade,
  Pagination,
  Scrollbar,
  Mousewheel,
} from "swiper/modules";
import example1Before from "../assets/samples/sample 1_Before.jpg";
import example1After from "../assets/samples/sample 1_After.jpg";
import example2Before from "../assets/samples/sample 2_Before.jpg";
import example2After from "../assets/samples/sample 2_After.jpg";
import example3Before from "../assets/samples/sample 3_Before.jpg";
import example3After from "../assets/samples/sample 3_After.jpg";
import example4Before from "../assets/tryPhoto/example_before.jpeg";
import example4After from "../assets/tryPhoto/example_after.jpeg";
import ReactCompareImage from "react-compare-image";
const data = [
  {
    before: example1Before,
    after: example1After,
    tags: "service_example1_tags",
  },
  {
    before: example2Before,
    after: example2After,
    tags: "service_example2_tags",
  },
  {
    before: example3Before,
    after: example3After,
    tags: "service_example3_tags",
  },
  {
    before: example4Before,
    after: example4After,
    tags: "service_example4_tags",
  },
];
export const Sample = (props) => {
  const { t } = useTranslation();
  return (
    <section id="sample">
      <div className="contInner">
        <h2 className="hdg_ttl02">
          <div>
            <span> {t("samples_title")}</span>
          </div>
        </h2>
        <div className="txt">
          <p>
            {t("samples_des_1")}
            <br />
            {t("samples_des_2")}
            <br />
            {t("samples_des_3")}
          </p>
        </div>
        <div className="swiperWrap">
          <Swiper
            className="swiper05"
            modules={[
              A11y,
              Autoplay,
              EffectFade,
              Pagination,
              Mousewheel,
              Navigation,
              Scrollbar,
            ]}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            navigation={{
              enabled: true,
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            loop={true}
            effect={"slide"}
            speed={1500}
            spaceBetween={30}
            allowTouchMove={false}
            slidesPerView={1}
            breakpoints={{
              768: {
                slidesPerView: 3,
              },
            }}
          >
            {data.map((item, index) => (
              <SwiperSlide key={index}>
                  <ReactCompareImage
                    className="compare_image"
                    leftImage={item.before}
                    rightImage={item.after}
                  />
                  <div className="caption">{t(item.tags)}</div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="swiper-button-prev"></div>
          <div className="swiper-button-next"></div>
        </div>
        {/* <div className="defBtn">
          <a href="#services">
            <span>{t("samples_service_btn")}</span>
          </a>
        </div> */}
      </div>
    </section>
  );
};
