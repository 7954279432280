/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import step1 from "../assets/howItWork/process_ico_1.svg";
import step2 from "../assets/howItWork/process_ico_2.svg";
import step3 from "../assets/howItWork/process_ico_3.svg";
import step4 from "../assets/howItWork/process_ico_4.svg";

export const HowItWork = (props) => {
  const { t } = useTranslation();
  return (
    <section id="how_it_work">
      <div className="contInner">
        <h2 className="hdg_ttl02">
          <div>
            <span>{t(`how_it_works_title`)}</span>
          </div>
        </h2>
        <div className="swiperWrap">
          <Swiper
            className="swiper04"
            modules={[Navigation]}
            loop={true}
            navigation={{
              enabled: true,
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            spaceBetween={50}
            slidesPerView={1}
            breakpoints={{
              810: {
                slidesPerView: 2, // Adjusted for tablet view
              },
              1024: {
                enabled: false,
                slidesPerView: 4, // Desktop view
              },
            }}
            
          >
            <SwiperSlide>
              <div className="swiper-slide item no01">
                <div className="box__icon">
                  <img alt="" src={step1} />
                </div>
                <h3 className="box__title">{t(`how_it_works_s1_title`)}</h3>
                <div className="box__subtitle">{t(`how_it_works_s1_des`)} </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide item no02">
                <div className="box__icon">
                  <img alt="" src={step2} />
                </div>
                <h3 className="box__title">{t(`how_it_works_s2_title`)}</h3>
                <div className="box__subtitle">{t(`how_it_works_s2_des`)} </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide item no03">
                <div className="box__icon">
                  <img alt="" src={step3} />
                </div>
                <h3 className="box__title">{t(`how_it_works_s3_title`)}</h3>
                <div className="box__subtitle">{t(`how_it_works_s3_des`)} </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide item no04">
                <div className="box__icon">
                  <img alt="" src={step4} />
                </div>
                <h3 className="box__title">{t(`how_it_works_s4_title`)}</h3>
                <div className="box__subtitle">{t(`how_it_works_s4_des`)} </div>
              </div>
            </SwiperSlide>
            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>
          </Swiper>
        </div>
      </div>
    </section>
  );
};
