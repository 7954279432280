import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { CLIENT_PORTAL } from "../constants";
import ReactGA from "react-ga4";
const PRICING_PLAN = {
  PAY_AS_U_GO: "pay_as_you_go",
  STANDARD: "standard",
  PREMIUM: "premium",
};
const PRICING_TYPE = {
  ONE_TIME: "one_time",
  MONTHLY: "monthly",
};
const currencyMap = {
  en: "USD",
  ja: "JPY",
  // add more language and currency pairs as needed
};
const data = [
  {
    title: "pricing_pay_as_you_go",
    subTitle: "pricing_pay_as_you_go_sub",
    description: "pricing_pay_as_you_go_des",
    credit: 0,
    usdPrice: 0,
    yenPrice: 0,
    plan: PRICING_PLAN.PAY_AS_U_GO,
    type: PRICING_TYPE.ONE_TIME,
  },
  {
    title: "pricing_100_standard",
    subTitle: "pricing_100_standard_sub",
    description: "pricing_100_standard_des",
    credit: 120,
    usdPrice: 60,
    yenPrice: 7500,
    plan: PRICING_PLAN.STANDARD,
    type: PRICING_TYPE.MONTHLY,
  },
  {
    title: "pricing_200_standard",
    subTitle: "pricing_200_standard_sub",
    description: "pricing_200_standard_des",
    credit: 240,
    usdPrice: 100,
    yenPrice: 12500,
    plan: PRICING_PLAN.STANDARD,
    type: PRICING_TYPE.MONTHLY,
  },
  {
    title: "pricing_800_standard",
    subTitle: "pricing_800_standard_sub",
    description: "pricing_800_standard_des",
    credit: 960,
    usdPrice: 200,
    yenPrice: 25000,
    plan: PRICING_PLAN.STANDARD,
    type: PRICING_TYPE.MONTHLY,
  },
  {
    title: "pricing_2k_standard",
    subTitle: "pricing_2k_standard_sub",
    description: "pricing_2k_standard_des",
    credit: 2400,
    usdPrice: 400,
    yenPrice: 50000,
    plan: PRICING_PLAN.STANDARD,
    type: PRICING_TYPE.MONTHLY,
  },
  {
    title: "pricing_5k_standard",
    subTitle: "pricing_5k_standard_sub",
    description: "pricing_5k_standard_des",
    credit: 6000,
    usdPrice: 750,
    yenPrice: 93750,
    plan: PRICING_PLAN.STANDARD,
    type: PRICING_TYPE.MONTHLY,
  },
  {
    title: "pricing_10k_premium",
    subTitle: "pricing_10k_premium_sub",
    description: "pricing_10k_premium_des",
    credit: 12000,
    usdPrice: 1100,
    yenPrice: 137500,
    plan: PRICING_PLAN.PREMIUM,
    type: PRICING_TYPE.MONTHLY,
  },
  {
    title: "pricing_20k_premium",
    subTitle: "pricing_20k_premium_sub",
    description: "pricing_20k_premium_des",
    credit: 24000,
    usdPrice: 2000,
    yenPrice: 250000,
    plan: PRICING_PLAN.PREMIUM,
    type: PRICING_TYPE.MONTHLY,
  },
  {
    title: "pricing_custom",
    subTitle: "pricing_custom_sub",
    description: "pricing_custom_des",
    credit: 0,
    usdPrice: 0,
    yenPrice: 0,
    plan: PRICING_PLAN.PREMIUM,
    type: PRICING_TYPE.MONTHLY,
  },
  {
    title: "pricing_10_bundles",
    subTitle: "pricing_10_bundles_sub",
    description: "pricing_10_bundles_des",
    credit: 12,
    usdPrice: 11,
    yenPrice: 1375,
    plan: PRICING_PLAN.PAY_AS_U_GO,
    type: PRICING_TYPE.ONE_TIME,
  },
  {
    title: "pricing_30_bundles",
    subTitle: "pricing_30_bundles_sub",
    description: "pricing_30_bundles_des",
    credit: 36,
    usdPrice: 31.5,
    yenPrice: 3938,
    plan: PRICING_PLAN.PAY_AS_U_GO,
    type: PRICING_TYPE.ONE_TIME,
  },
  {
    title: "pricing_50_bundles",
    subTitle: "pricing_50_bundles_sub",
    description: "pricing_50_bundles_des",
    credit: 60,
    usdPrice: 50,
    yenPrice: 6250,
    plan: PRICING_PLAN.PAY_AS_U_GO,
    type: PRICING_TYPE.ONE_TIME,
  },
  {
    title: "pricing_100_bundles",
    subTitle: "pricing_100_bundles_sub",
    description: "pricing_100_bundles_des",
    credit: 120,
    usdPrice: 80,
    yenPrice: 10000,
    plan: PRICING_PLAN.PAY_AS_U_GO,
    type: PRICING_TYPE.ONE_TIME,
  },
  {
    title: "pricing_200_bundles",
    subTitle: "pricing_200_bundles_sub",
    description: "pricing_200_bundles_des",
    credit: 240,
    usdPrice: 120,
    yenPrice: 15000,
    plan: PRICING_PLAN.PAY_AS_U_GO,
    type: PRICING_TYPE.ONE_TIME,
  },
  {
    title: "pricing_800_bundles",
    subTitle: "pricing_800_bundles_sub",
    description: "pricing_800_bundles_des",
    credit: 960,
    usdPrice: 320,
    yenPrice: 40000,
    plan: PRICING_PLAN.PAY_AS_U_GO,
    type: PRICING_TYPE.ONE_TIME,
  },
  {
    title: "pricing_2k_bundles",
    subTitle: "pricing_2k_bundles_sub",
    description: "pricing_2k_bundles_des",
    credit: 2400,
    usdPrice: 500,
    yenPrice: 62500,
    plan: PRICING_PLAN.PAY_AS_U_GO,
    type: PRICING_TYPE.ONE_TIME,
  },
];

const features = [
  {
    title: "free_credits",
    types: [
      PRICING_PLAN.PAY_AS_U_GO,
      PRICING_PLAN.STANDARD,
      PRICING_PLAN.PREMIUM,
    ],
  },
  {
    title: "free_watermark",
    types: [
      PRICING_PLAN.PAY_AS_U_GO,
      PRICING_PLAN.STANDARD,
      PRICING_PLAN.PREMIUM,
    ],
  },
  {
    title: "free_file_resolution",
    types: [
      PRICING_PLAN.PAY_AS_U_GO,
      PRICING_PLAN.STANDARD,
      PRICING_PLAN.PREMIUM,
    ],
  },
  {
    title: "10_item_limited",
    types: [PRICING_PLAN.PAY_AS_U_GO],
  },
  {
    title: "50_item_limited",
    types: [PRICING_PLAN.STANDARD],
  },
  {
    title: "100_item_limited",
    types: [PRICING_PLAN.PREMIUM],
  },
  {
    title: "editor_tool_supported",
    types: [
      PRICING_PLAN.PAY_AS_U_GO,
      PRICING_PLAN.STANDARD,
      PRICING_PLAN.PREMIUM,
    ],
  },
  {
    title: "1_week_storage",
    types: [PRICING_PLAN.PAY_AS_U_GO],
  },
  {
    title: "1_month_storage",
    types: [PRICING_PLAN.STANDARD],
  },
  {
    title: "3_month_storage",
    types: [PRICING_PLAN.PREMIUM],
  },
  {
    title: "credit_rollover",
    types: [
      PRICING_PLAN.PAY_AS_U_GO,
      PRICING_PLAN.STANDARD,
      PRICING_PLAN.PREMIUM,
    ],
  },
  {
    title: "free_support",
    types: [PRICING_PLAN.STANDARD, PRICING_PLAN.PREMIUM],
  },

  {
    title: "keep_same_rate",
    types: [PRICING_PLAN.STANDARD, PRICING_PLAN.PREMIUM],
  },
  {
    title: "cancel_anytime",
    types: [PRICING_PLAN.STANDARD, PRICING_PLAN.PREMIUM],
  },

  // {
  //   title: "bank_transfer_supported",
  //   types: [PRICING_PLAN.PREMIUM],
  // },
];
export const Pricing = (props) => {
  const { t, i18n } = useTranslation();
  const [currency, setCurrency] = useState(currencyMap[i18n.language]);
  const [planType, setPlanType] = useState(PRICING_PLAN.PAY_AS_U_GO);
  const renderPrice = (plan) => {
    if (plan.title === "pricing_pay_as_you_go") {
      return (
        <div className="plan_fee" style={{ opacity: "1" }}>
          {t(`pricing_free`)}
        </div>
      );
    }
    if (plan.title === "pricing_custom") {
      return (
        <div className="plan_fee">
          XX<div class="payment_type">{t(`price_monthly`)}</div>
        </div>
      );
    }
    return (
      <div className="plan_fee">
        {currency === "USD" ? "$" : "¥"}
        {currency === "USD"
          ? plan.usdPrice.toLocaleString("en-US")
          : plan.yenPrice.toLocaleString("ja-JP")}
        {plan.type === PRICING_TYPE.MONTHLY && (
          <div class="payment_type">{t(`price_monthly`)}</div>
        )}
      </div>
    );
  };
  const handleActionClick = (planName: string) => {
    window.open(CLIENT_PORTAL, "_blank");
    ReactGA.event({
      category: "Landing Page",
      action: "Click",
      label: "Go detail of plan" + planName,
    });
  };
  const renderAction = (plan) => {
    if (plan.title === "pricing_pay_as_you_go") {
      return (
        <button
          className="plan_action btn btn-primary"
          onClick={() => handleActionClick(plan.title)}
        >
          {t(`start_now`)}
        </button>
      );
    }
    if (plan.title === "pricing_custom") {
      return (
        <a href="./#contact" className="plan_action btn btn-primary">
          {t(`pricing_call_now`)}
        </a>
      );
    }
    if (plan.type === PRICING_TYPE.ONE_TIME) {
      return (
        <button
          className="plan_action btn btn-primary"
          onClick={() => handleActionClick(plan.title)}
        >
          {t(`pricing_buy_now`)}
        </button>
      );
    }
    return (
      <button
        className="plan_action btn btn-primary"
        onClick={() => handleActionClick(plan.title)}
      >
        {t(`pricing_subscribe`)}
      </button>
    );
  };
  const renderPlanCredit = (item) => {
    switch (item.title) {
      case "pricing_pay_as_you_go":
        return t("free");
      case "pricing_custom":
        return t("contact_us");
      default:
        return `+ ${item.credit.toLocaleString()} ${t("service_credit")}`;
    }
  };

  useEffect(() => {
    setCurrency(currencyMap[i18n.language]);
  }, [i18n.language]);
  return (
    <section id="pricing">
      <div className="contInner">
        <h2 className="hdg_ttl02">
          <div>
            <span>{t(`pricing_title`)}</span>
          </div>
        </h2>
        <div className="txt">
          {t("pricing_des")}
          <p className="txt_note">{t("pricing_note")}</p>
        </div>
        <div className="inner">
          <div className="filter row">
            <div className="col col-sm-12  pricing_filter">
              <div className="pricing_filter_group">
                {[
                  PRICING_PLAN.PAY_AS_U_GO,
                  PRICING_PLAN.STANDARD,
                  PRICING_PLAN.PREMIUM,
                ].map((plan, index) => (
                  <div
                    key={index}
                    className={
                      "pricing_filter_item " +
                      (plan === planType ? "active" : "")
                    }
                    onClick={() => setPlanType(plan)}
                  >
                    {t(`pricing_cate_${plan}`)}
                  </div>
                ))}
              </div>
            </div>
            <div className="col col-sm-12 price-plan-feature">
              <div className="plan-features row">
                {features
                  .filter((feature) => feature.types.includes(planType))
                  // .sort((a, b) => t(a.title).length - t(b.title).length)
                  .map((feature, index) => (
                    <div
                      key={index}
                      className={"pricing_item_feature  col-sm-12 "}
                    >
                      <FontAwesomeIcon
                        style={{ marginRight: "5px" }}
                        icon={faCircleCheck}
                        color={"#3678f7"}
                      />
                      {t(feature.title)}
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="price_plans">
            <div className="row pricing-row g-5">
              {data
                .filter((item) => item.plan === planType)
                .map((item, index) => (
                  <div
                    key={index}
                    className={`col col-sm-6 col-md-4 pricing-item-border`}
                  >
                    <div className={`pricing-item`}>
                      <div className="plan_title">{t(item.title)}</div>
                      <div className="plan_sub">{t(item.subTitle)}</div>
                      {item.type === PRICING_TYPE.ONE_TIME &&
                        item.credit > 0 && (
                          <div className="ribbon">{t(`pricing_packages`)}</div>
                        )}
                      <div className="plan_credit">
                        {renderPlanCredit(item)}
                      </div>
                      <div className="plan_des"> {t(item.description)}</div>
                      {renderPrice(item)}
                      {renderAction(item)}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
