import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";
import {
  A11y,
  Navigation,
  Autoplay,
  EffectFade,
  Pagination,
  Scrollbar,
  Mousewheel,
} from "swiper/modules";

import ReactCompareImage from "react-compare-image";
import beforeImg from "../assets/tryPhoto/example_before.jpeg";
import afterImg from "../assets/tryPhoto/example_after.jpeg";
import "./header.css";
export const Header = (props) => {
  const { t } = useTranslation();
  return (
    <header id="header">
      <div className="swiper header-swiper">
        <Swiper
          modules={[
            A11y,
            Autoplay,
            EffectFade,
            Pagination,
            Mousewheel,
            Navigation,
            Scrollbar,
          ]}
          loop={true}
          speed={1500}
          spaceBetween={0}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
         
          pagination={{ clickable: true }}
          effect={"fade"}
          slidesPerView={1}
          allowTouchMove={false}
        >
          <SwiperSlide>
            <img src="/img/header/mv01.png" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="/img/header/mv02.png" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="/img/header/mv03.png" alt="" />
          </SwiperSlide>
        </Swiper>
        {/* <div className="swiper-button-prev"></div>
        <div className="swiper-button-next"></div> */}
        
      </div>
      <div id="mvMessage" className="contInner">
          <div className="txtWrap"
            style={{
              width: "60%",
              zIndex: 2,
            }}
          >
            <div className="circleWrap">
              {/* <div className="circle">{t(`banner_ai`)}</div> */}
              <div className="circle">{t(`banner_easy`)}</div>
              <div className="circle">{t(`banner_instance`)}</div>
              <div className="circle">{t(`banner_massive`)}</div>
            </div>
            <h2 className="htg_tti01">{t(`banner_title`)}</h2>
            <div className="txt">
              <span>
                {t(`banner_subtitle`)
                  .split("\n")
                  .map((line, ind) => (
                    <React.Fragment key={ind}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                {t(`banner_subtitle2`)
                  .split("\n")
                  .map((line, ind) => (
                    <React.Fragment key={ind}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
              </span>
            </div>
            <div className="catch">
              <span>{t(`banner_quote`)}</span>
            </div>
          </div>
          <div className="ph">
            <ReactCompareImage
              className="compare_image"
              leftImage={beforeImg}
              rightImage={afterImg}
            />
          </div>
        </div>
    </header>
  );
};
