import React, { useState } from "react";
import { useTranslation } from "react-i18next";
const data = [
  {
    q: "faq_q1",
    a: "faq_a1",
  },
  {
    q: "faq_q2",
    a: "faq_a2",
  },
  {
    q: "faq_q3",
    a: "faq_a3",
  },
  {
    q: "faq_q4",
    a: "faq_a4",
  },
  {
    q: "faq_q5",
    a: "faq_a5",
  },
  {
    q: "faq_q6",
    a: "faq_a6",
  },
  {
    q: "faq_q7",
    a: "faq_a7",
  },
];
export const FAQ = (props) => {
  const { t } = useTranslation();
  const [active, setActive] = useState("faq_q1");
  return (
    <section id="faq">
      <div className="contInner">
        <h2 className="hdg_ttl02">
          <div>
            <span>{t(`faq_title`)}</span>
          </div>
        </h2>
        {data.map((item, index) => {
          return (
            <dl
              key={index}
              className={"ac_list " + (active === item.q ? "active" : "")}
              onClick={() => setActive(item.q)}
            >
              <dt className="ac_head">
                <p>{t(item.q)}</p>
                <i className="cliper_toggle_icon fa fa-angle-right"></i>
              </dt>
              <dd className="ac_body">
                <p>{t(item.a)}</p>
              </dd>
            </dl>
          );
        })}
      </div>
    </section>
  );
};
