import React from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  A11y,
  Navigation,
  Autoplay,
  EffectFade,
  Pagination,
  Scrollbar,
  Mousewheel,
} from "swiper/modules";
const data = [
  {
    title: "review_title1",
    content: "review_content1",
    reviewer: "reviewer1",
    role: "role1",
    color: "color01",
  },
  {
    title: "review_title2",
    content: "review_content2",
    reviewer: "reviewer2",
    role: "role2",
    color: "color02",
  },
  {
    title: "review_title3",
    content: "review_content3",
    reviewer: "reviewer3",
    role: "role3",
    color: "color03",
  },
  {
    title: "review_title1",
    content: "review_content1",
    reviewer: "reviewer1",
    role: "role1",
    color: "color01",
  },
  {
    title: "review_title2",
    content: "review_content2",
    reviewer: "reviewer2",
    role: "role2",
    color: "color02",
  },
  {
    title: "review_title3",
    content: "review_content3",
    reviewer: "reviewer3",
    role: "role3",
    color: "color03",
  },
];
export const Reviews = (props) => {
  const { t } = useTranslation();
  return (
    <section id="reviews">
      <div className="contInner">
        <h2 className="hdg_ttl02">
          <div>
            <span>{t(`review_title`)}</span>
          </div>
        </h2>
        <div className="swiperWrap">
          <Swiper
            className="swiper09"
            modules={[
              A11y,
              Autoplay,
              EffectFade,
              Pagination,
              Mousewheel,
              Navigation,
              Scrollbar,
            ]}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            loop={true}
            effect={"slide"}
            speed={1500}
            spaceBetween={30}
            slidesPerView={1}
            breakpoints={{
              810: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
          >
            {data.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="cont">
                  <div className={"title " + item.color}>
                    <span>{t(item.title)}</span>
                  </div>
                  <div className="txt">
                    <p>{t(item.content)}</p>
                  </div>
                </div>
                <div className={"review " + item.color}>
                  <div className="left">
                    <div className="author__name"> {t(item.reviewer)}</div>
                    <p className="author__designation">{t(item.role)}</p>
                  </div>
                  <div className="right">
                    <div className="star">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                      >
                        <path
                          d="M8.522,1.546a.5.5,0,0,1,.955,0l1.538,4.977a.5.5,0,0,0,.478.352h5.028a.5.5,0,0,1,.3.9l-4.111,3.14a.5.5,0,0,0-.174.545l1.56,5.048a.5.5,0,0,1-.781.545L9.3,13.983a.5.5,0,0,0-.607,0L4.681,17.05a.5.5,0,0,1-.781-.545l1.56-5.048a.5.5,0,0,0-.174-.545L1.175,7.773a.5.5,0,0,1,.3-.9H6.507a.5.5,0,0,0,.478-.352Z"
                          fill="#fa0"
                        />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                      >
                        <path
                          d="M8.522,1.546a.5.5,0,0,1,.955,0l1.538,4.977a.5.5,0,0,0,.478.352h5.028a.5.5,0,0,1,.3.9l-4.111,3.14a.5.5,0,0,0-.174.545l1.56,5.048a.5.5,0,0,1-.781.545L9.3,13.983a.5.5,0,0,0-.607,0L4.681,17.05a.5.5,0,0,1-.781-.545l1.56-5.048a.5.5,0,0,0-.174-.545L1.175,7.773a.5.5,0,0,1,.3-.9H6.507a.5.5,0,0,0,.478-.352Z"
                          fill="#fa0"
                        />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                      >
                        <path
                          d="M8.522,1.546a.5.5,0,0,1,.955,0l1.538,4.977a.5.5,0,0,0,.478.352h5.028a.5.5,0,0,1,.3.9l-4.111,3.14a.5.5,0,0,0-.174.545l1.56,5.048a.5.5,0,0,1-.781.545L9.3,13.983a.5.5,0,0,0-.607,0L4.681,17.05a.5.5,0,0,1-.781-.545l1.56-5.048a.5.5,0,0,0-.174-.545L1.175,7.773a.5.5,0,0,1,.3-.9H6.507a.5.5,0,0,0,.478-.352Z"
                          fill="#fa0"
                        />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                      >
                        <path
                          d="M8.522,1.546a.5.5,0,0,1,.955,0l1.538,4.977a.5.5,0,0,0,.478.352h5.028a.5.5,0,0,1,.3.9l-4.111,3.14a.5.5,0,0,0-.174.545l1.56,5.048a.5.5,0,0,1-.781.545L9.3,13.983a.5.5,0,0,0-.607,0L4.681,17.05a.5.5,0,0,1-.781-.545l1.56-5.048a.5.5,0,0,0-.174-.545L1.175,7.773a.5.5,0,0,1,.3-.9H6.507a.5.5,0,0,0,.478-.352Z"
                          fill="#fa0"
                        />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                      >
                        <path
                          d="M8.522,1.546a.5.5,0,0,1,.955,0l1.538,4.977a.5.5,0,0,0,.478.352h5.028a.5.5,0,0,1,.3.9l-4.111,3.14a.5.5,0,0,0-.174.545l1.56,5.048a.5.5,0,0,1-.781.545L9.3,13.983a.5.5,0,0,0-.607,0L4.681,17.05a.5.5,0,0,1-.781-.545l1.56-5.048a.5.5,0,0,0-.174-.545L1.175,7.773a.5.5,0,0,1,.3-.9H6.507a.5.5,0,0,0,.478-.352Z"
                          fill="#fa0"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};
